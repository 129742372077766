import {
  CREATE_SLIDE_GALLERY_FAIL,
  CREATE_SLIDE_GALLERY_REQUEST,
  CREATE_SLIDE_GALLERY_SUCCESS,
  DELETE_SLIDE_GALLERY_FAIL,
  DELETE_SLIDE_GALLERY_REQUEST,
  DELETE_SLIDE_GALLERY_SUCCESS,
  FETCH_SLIDE_GALLERY_FAIL,
  FETCH_SLIDE_GALLERY_REQUEST,
  FETCH_SLIDE_GALLERY_SUCCESS,
  SHOW_SLIDE_GALLERY_FAIL,
  SHOW_SLIDE_GALLERY_REQUEST,
  SHOW_SLIDE_GALLERY_SUCCESS,
} from "../ActionType/GallaryType/SliderGalary";

export const getSlideGalleryReducer = (
  state = { loading: true, slideGallerys: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SLIDE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SLIDE_GALLERY_SUCCESS:
      return {
        loading: false,
        slideGallerys: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_SLIDE_GALLERY_FAIL:
      return {
        loading: false,
        slideGallerys: [],
        error: "",
      };
    default:
      return state;
  }
};

export const createSlideGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SLIDE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SLIDE_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_SLIDE_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteSlideGalleryReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_SLIDE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SLIDE_GALLERY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case DELETE_SLIDE_GALLERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showSlideGalleryReducer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_SLIDE_GALLERY_REQUEST:
      return {
        loading: true,
      };
    case SHOW_SLIDE_GALLERY_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_SLIDE_GALLERY_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};
