import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBgCatalogeAction, getBgCatalogeAction, updateBgCatalogeAction } from "../../Action/CatalogeAction/BgCatalogeAction";
import { useNavigate } from "react-router-dom";

const BgCataloge = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(id)
    if (id == null || id == "" || id == undefined) {
      console.log('sdklf')
      dispatch(createBgCatalogeAction(image));
      return;
    } else {
      dispatch(updateBgCatalogeAction(id, image));
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      // Set the selected image to state
      setImage(selectedImage);

      // Display a preview of the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const getBgCataloge = useSelector((state) => state.getBgCataloge);
  const { cataloges } = getBgCataloge;

  useEffect(() => {
    dispatch(getBgCatalogeAction());
  }, []);

  useEffect(() => {
    if (cataloges === undefined || cataloges.length === 0) {
      return;
    }
    // Assuming you want the ID of the first cataloge item, adjust as needed
    setId(cataloges[0].id);
    setImagePreview(cataloges[0].bg_image)
  }, [cataloges]);
  const navigate=useNavigate();
  return (
    <div className="bg-white m-auto mt-2 w-full lg:w-11/12 rounded-md shadow-sm p-5">
      <div
        onClick={() => navigate(-1)}
        className="bg-blue-500 cursor-pointer lg:w-1/12 text-center mb-4 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      >
        Back
      </div>
      <form action="" className="flex flex-col">
        <input
          type="file"
          onChange={handleImageChange}
          className="border-2 rounded-md border-black p-2"
        />
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            className="mt-2 rounded-md border-2 border-black p-2"
          />
        )}
        <input
          type="submit"
          onClick={handleSubmit}
          className="bg-indigo-500 mt-4 cursor-pointer hover:bg-indigo-600 rounded-md shadow-md px-2 py-2 text-white duration-500"
        />
      </form>
    </div>
  );
};

export default BgCataloge;
