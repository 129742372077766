import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { createBackgroundContactAction, getBackgroundContactAction } from "../../Action/backgroundContactAction/BackgroundContactAction";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    image: null,
  });

  const [tel, setTel] = useState(null);
  const [map, setMap] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(createBackgroundContactAction(formData.image,tel,address,map,email));
    dispatch(getBackgroundContactAction())
    dispatch(getBackgroundContactAction())
    navigate(-1)
    // Handle form submission here (send data to server, etc.)
  };

  useEffect(()=>{
    dispatch(getBackgroundContactAction())
  },[])
  const getBackgrounContact=useSelector(state=>state.getBackgrounContact);
  const {bgContacts,loading}=getBackgrounContact;
  const navigate = useNavigate();


  return (
    <>
    {
      !bgContacts || loading?"":<div className="container mx-auto mt-8">
      <form
        onSubmit={handleSubmit}
        className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
      >
        <div className="flex  gap-2 items-center">
          <div
            onClick={() => navigate(-1)}
            className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          >
            Back
          </div>
          <div>
            <h2 className="text-2xl font-semibold ">Edit Slider Form</h2>
          </div>
        </div>
        <div className="flex flex-wrap mt-2">
          <div className=" flex w-full gap-3">
            <div className="w-full">
              <label htmlFor="">Tel : </label>
              <input
                type="text"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                className="border-2  w-full  border-indigo-500 hover:border-indigo-600 outline-none p-1"
                placeholder="tel"
              />
            </div>

            <div className="w-full">
              <label htmlFor="">Email : </label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-indigo-500 hover:border-indigo-600 outline-none w-full  p-1"
                placeholder="Email"
              />
            </div>
          </div>
          <div className=" flex w-full gap-3">
            <div className="w-full">
              <label htmlFor="">Address : </label>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                type="text"
                className="border-2 border-indigo-500 hover:border-indigo-600 outline-none w-full  p-1"
                placeholder="Address"
              />
            </div>

            <div className="w-full">
              <label htmlFor="">Map : </label>
              <input
                value={map}
                onChange={(e) => setMap(e.target.value)}
                type="text"
                className="border-2 border-indigo-500 hover:border-indigo-600 outline-none w-full  p-1"
                placeholder="Map"
              />
            </div>
          </div>
        </div>
        <label
          htmlFor="image"
          className="block text-gray-700 text-sm font-bold mt-4 mb-2"
        >
          Background Image:
        </label>
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />

        {imagePreview && (
          <div className="mt-2">
            <img
              src={imagePreview}
              alt="Image Preview"
              className="max-w-full h-auto"
            />
          </div>
        )}

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </div>
    }
    
    </>
  );
};

export default ContactForm;
