import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteImageActivityAction,
  showImageByActivityIdAction,
  updateImageActivityAction,
} from "../../Action/ImageActivityAction/ImageActivityAction";

const ImageActivity = () => {
  const { id } = useParams();
  const showImageByActivityId = useSelector(
    (state) => state.showImageByActivityId
  );
  const { activitys,loading } = showImageByActivityId;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showImageByActivityIdAction(id));
  }, []);


  const [image,setImage]=useState(null);
  const [idd, setId] = useState(null);
  const [isOpenDeleteModalView, setIsOpenDeleteModalView] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);

  return (
    <>
    {
        loading || !activitys?"":<div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className=" border rounded-lg">
              <table className="min-w-full divide-y bg-white divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      ID
                    </th>

                    <th className="text-left">Image</th>

                    <th className="text-left">Edit</th>

                    <th className="text-left">Delete</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {activitys.map((activity, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {index + 1}
                        </td>

                        <td className="m-auto text-center right-0">
                          <img
                            src={activity.image}
                            className="bg-cover w-20 h-20"
                            alt=""
                          />
                        </td>

                        <td className="">
                          <a
                            className="text-green-500 cursor-pointer hover:text-green-700"
                            onClick={() => {
                              setIsOpenUpdateModal(true);
                              setId(activity.id);
                            }}
                          >
                            Edit
                          </a>
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                          <a
                            className="text-red-500 hover:text-red-700"
                            href="#"
                            onClick={() => {
                              setIsOpenDeleteModalView(true);
                              setId(activity.id);
                            }}
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    }
      
      {isOpenDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsOpenDeleteModalView(false);
                  setId(null);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteImageActivityAction(idd));
                  dispatch(showImageByActivityIdAction(id));
                  dispatch(showImageByActivityIdAction(id));
                  setId(null);
                  setIsOpenDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpenUpdateModal && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to Update Data?
            </p>

            <div>
              <input
                type="file"
                className="outline-none border-2 p-2 border-none"
                onChange={(e)=>{
                    setImage(e.target.files[0])
                }}
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsOpenUpdateModal(false);
                  setId(null);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(updateImageActivityAction(idd,image));
                  dispatch(showImageByActivityIdAction(id));
                  dispatch(showImageByActivityIdAction(id));

                  
                  setId(null);
                  setIsOpenUpdateModal(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageActivity;
