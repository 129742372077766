import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";
import { createAboutAction, getAboutAction } from "../../Action/About/aboutAction";
import { createGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import { useNavigate } from "react-router-dom";

const FormGallery = () => {
  const [formData, setFormData] = useState({
    title: "title",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
    image1: null,
    image2: null,
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createAboutAction(
        formData.title,
        formData.content_arabic,
        formData.content_english,
        formData.content_turkish,
        formData.image,

      )
    );

    dispatch(getAboutAction(1))
    dispatch(getAboutAction(1))
    navigate(-1);
    // Handle form submission here (send data to server, etc.)
  };
  useEffect(()=>{
    dispatch(getAboutAction(1))
  },[])
  const getAbout=useSelector(state=>state.getAbout);
  const {abouts,loading}=getAbout;
  const navigate = useNavigate();
  return (
<>

{
  loading || !abouts?"":<div className="container mx-auto mt-8">
  <form
    onSubmit={handleSubmit}
    className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
  >
    <div className="flex  gap-2 items-center">
      <div
        onClick={() => navigate(-1)}
        className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      >
        Back
      </div>
      <div>
        <h2 className="text-2xl font-semibold ">About Form</h2>
      </div>
    </div>


    <label
      htmlFor="content_arabic"
      className="block text-gray-700 text-sm font-bold mt-4 mb-2"
    >
      Content (Arabic):
    </label>
    <textarea
      id="content_arabic"
      name="content_arabic"
      value={formData.content_arabic}
      onChange={handleChange}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      required
    ></textarea>

    <label
      htmlFor="content_english"
      className="block text-gray-700 text-sm font-bold mt-4 mb-2"
    >
      Content (English):
    </label>
    <textarea
      id="content_english"
      name="content_english"
      value={formData.content_english}
      onChange={handleChange}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      required
    ></textarea>

    <label
      htmlFor="content_turkish"
      className="block text-gray-700 text-sm font-bold mt-4 mb-2"
    >
      Content (Turkish):
    </label>
    <textarea
      id="content_turkish"
      name="content_turkish"
      value={formData.content_turkish}
      onChange={handleChange}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      required
    ></textarea>

    <label
      htmlFor="image"
      className="block text-gray-700 text-sm font-bold mt-4 mb-2"
    >
      Background Image:
    </label>
    <input
      type="file"
      id="image"
      name="image"
      accept="image/*"
      onChange={handleChange}
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
    />


    
{
  abouts && abouts.length<=0?<button
  type="submit"
  className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
>
  Submit
</button>:""
}
    
  </form>
</div>
}

</>
    
  );
};

export default FormGallery;

