import React, { useState } from "react";
// import './App.css';
import { useDispatch } from "react-redux";
import { createAboutAction } from "../../Action/About/aboutAction";
import { createGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa6";

const GalleryForm = () => {
  const [images, setImages] = useState([]);
  const handleFileChange = (e) => {
    const files = e.target.files;
    setImages([...files]); // Spread the files into a new array and set as state
  };

  const dispatch=useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(createGalleryAction(images))
  };
  const navigate = useNavigate();
  return (
    <div className="container mx-auto mt-8">
      <form
        onSubmit={handleSubmit}
        className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
      >
        <div className="flex  gap-2 items-center">
          <div
            onClick={() => navigate(-1)}
            className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          >
            Back
          </div>
          <div>
            <h2 className="text-2xl font-semibold ">Gallery Form</h2>
          </div>
        </div>

        <div>
      <input type="file" className="input border-2 w-full my-2" multiple onChange={handleFileChange} />
      <button onClick={handleSubmit} className="outline-none bg-violet-500 text-white px-4 py-1 rounded-md shadow-md duration-500  hover:bg-violet-600">Submit</button>
    </div>

    
      </form>
    </div>
  );
};

export default GalleryForm;
