import axios from "axios";
// import CryptoJS from "crypto-js";

import Swal from "sweetalert2";
import { USER_LOGIN_REQUSET, USER_LOGIN_SUCCESS } from "../ActionType/loginTypes";
import { api } from "../Api/api";
import { FETCH_USER_FAIL, FETCH_USER_SUCCESS } from "../ActionType/UserAuth/UserAuthType";


export const login = (email, password) => (dispatch) => {
  // console.log(dispatch)
  loginApi
    .post("/login", {
      email,
      password,
    })
    .then((response) => {
      console.log(response)
      dispatch({ type: USER_LOGIN_REQUSET });
      if (response.data.status == 200) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: response.data.userInfo,
          error: "",
        });
        // console.log(response.data.token)
        sessionStorage.setItem("tokendefault22330998@34#", response.data.token);
        window.location.reload();
      } else if (response.data.status == 404) {
        
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("locationId");
        sessionStorage.removeItem("notifi");
        sessionStorage.removeItem("loggedIn");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.error,
          timer: 2000,
        });
      }
    })
    .catch((err) => {
      console.log(err)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const getToken = () => {
  const token = sessionStorage.getItem("tokendefault22330998@34#");
  
  return token;
};
const URL="https://admin.omtayalitim.com/app/api/v1"
export const loginApi = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/v1",
  baseURL: URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
})

export const logOut = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post("logout", {}, config)
    .then((response) => {
      if (response.data.status == 200) {
        // const encData = encryptData("false", "8");
        sessionStorage.removeItem("tokendefault22330998@34#");
  
        sessionStorage.removeItem("loggedIn");
        window.location.reload();
      }
      // sessionStorage.removeItem("token");
      // sessionStorage.removeItem("loggedIn");
    })
    .catch((error) => {
      console.log(error);
    });
};


export const getUserAuthAction = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get("", config)
    .then((response) => {
      // console.log(response)
      if (response.status == 200) {
        dispatch({
          type:FETCH_USER_SUCCESS,
          payload:response.data
        })
        // console.log(response.data)
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type:FETCH_USER_FAIL,
        // payload:response.data
      })
    });
};

export const changePasswordAction = (id,password) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`change/${id}`,{password} ,config)
    .then((response) => {
      if (response.status == 200) {
          Swal.fire({
            icon:"success",
            timer:1500,
            text:"Password Was changed",
            title:"Good Job"
          })
        
      }
    })
    .catch((error) => {
      console.log(error);
      
    });
};

