import { CREATE_BG_CATALOGE_FAIL, CREATE_BG_CATALOGE_REQUEST, CREATE_BG_CATALOGE_SUCCESS, DELETE_BG_CATALOGE_FAIL, DELETE_BG_CATALOGE_REQUEST, DELETE_BG_CATALOGE_SUCCESS, FETCH_BG_CATALOGE_FAIL, FETCH_BG_CATALOGE_REQUEST, FETCH_BG_CATALOGE_SUCCESS, SHOW_BG_CATALOGE_FAIL, SHOW_BG_CATALOGE_REQUEST, SHOW_BG_CATALOGE_SUCCESS, UPDATE_BG_CATALOGE_FAIL, UPDATE_BG_CATALOGE_REQUEST, UPDATE_BG_CATALOGE_SUCCESS } from "../ActionType/CatalogeType/bgCatalogeType";

  export const getBgCatalogeReducer = (
      state = { loading: true, cataloges: [],total:0, error: "" },
      action
    ) => {
      switch (action.type) {
        case FETCH_BG_CATALOGE_REQUEST:
          return {
            loading: true,
          };
        case FETCH_BG_CATALOGE_SUCCESS:
          return {
            loading: false,
            cataloges: action.payload,
            total:action.total,
            error: "",
          };
        case FETCH_BG_CATALOGE_FAIL:
          return {
            loading: false,
            cataloges: [],
            error: "",
          };
        default:
          return state;
      }
    };
  
    
  export const createBgCatalogeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_BG_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case CREATE_BG_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case CREATE_BG_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const deleteBgCatalogeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_BG_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case DELETE_BG_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_BG_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  
  export const showBgCatalogeReducer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_BG_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case SHOW_BG_CATALOGE_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_BG_CATALOGE_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateBgCatalogeReducer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_BG_CATALOGE_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_BG_CATALOGE_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case UPDATE_BG_CATALOGE_FAIL:
        return {
          loading: false,
          data: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };