import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { thunk } from "redux-thunk";
import {
  deleteGalleryReducer,
  getGalleryReducer,
  showGalleryReducer,
} from "../Reducer/GalleryReducer";
import { getUserAuthReducer, loginUserReducer } from "../Reducer/loginReducer";
import {
  createAboutReduer,
  deleteAboutReduer,
  getAboutReduer,
  showAboutReduer,
  updateAboutReduer,
} from "../Reducer/aboutReducer";
import {
  createCatalogeReducer,
  deleteCatalogeReducer,
  getCatalogeReducer,
  showCatalogeReducer,
  updateCatalogeReduer,
} from "../Reducer/catalogeReducer";
import {
  createActivityReduer,
  deleteActivitytReduer,
  getActivityReduer,
  showActivityReduer,
  updateActivityReduer,
} from "../Reducer/activityReducer";
import {
  createSliderReducer,
  deleteSliderReducer,
  getSliderReducer,
  showSliderReducer,
  updateSliderReducer,
} from "../Reducer/sliderReducer";
import {
  createProductReducer,
  deleteProductReducer,
  getProductReducer,
  showProductReducer,
  updateProductReduer,
} from "../Reducer/productReducer";
import { createPDFReduer, deletePdfReduer, getPdfReduer } from "../Reducer/PdfReducer";
import { createPictureReduer, deletePictureReduer, getPictureReduer } from "../Reducer/PictureReducer";
import { createSlideGalleryReducer, deleteSlideGalleryReducer, getSlideGalleryReducer, showSlideGalleryReducer } from "../Reducer/SliderGalleryReducer";
import { createSliderProductReduer, deleteSliderProductReduer, getSliderProductReduer, showProductSliderByProductIdReducer, showSliderProductReduer, updateSliderProductReduer } from "../Reducer/ShowDetailsImageProductReducer";
import { createBackgrounContactReduer, deleteBackgrounContactReduer, getBackgrounContactReduer, showBackgrounContactReduer, updateBackgrounContactReduer } from "../Reducer/backgroundContactReducer";
import { createHomeReducer, deleteHomeReducer, getHomeReducer, showHomeReducer, updateHomeReducer } from "../Reducer/HomeReducer";
import { getBgActivityReduer } from "../Reducer/bgActivityReducer";
import { getBgCatalogeReducer } from "../Reducer/bgCatalogeReducer";
import { getBgGalleryReducer } from "../Reducer/bgGalleryReducer";
import { getBgProductReducer } from "../Reducer/bgProductReducer";
import { createImageActivityReducer, deleteImageActivityReducer, showImageByActivityIdReducer } from "../Reducer/ImageActivityReducer";

const rootReducer = combineReducers({

  getUserAuth:getUserAuthReducer,
  getGallery: getGalleryReducer,
  deleteGallery: deleteGalleryReducer,
  showGallery: showGalleryReducer,

  getSlideGallery: getSlideGalleryReducer,
  deleteSlideGallery: deleteSlideGalleryReducer,
  showSlideGallery: showSlideGalleryReducer,
  createSlideGallery:createSlideGalleryReducer,
  

  loginUser: loginUserReducer,



  getAbout: getAboutReduer,
  createAbout: createAboutReduer,
  deleteAbout: deleteAboutReduer,
  showAbout: showAboutReduer,
  updateAbout: updateAboutReduer,

  getCataloge: getCatalogeReducer,
  createCataloge: createCatalogeReducer,
  deleteCataloge: deleteCatalogeReducer,
  showCataloge: showCatalogeReducer,
  updateCataloge: updateCatalogeReduer,

  getActivity: getActivityReduer,
  createActivity: createActivityReduer,
  deleteActivity: deleteActivitytReduer,
  showActivity: showActivityReduer,
  updateActivity: updateActivityReduer,

  getSlider: getSliderReducer,
  createSlider: createSliderReducer,
  deleteSlider: deleteSliderReducer,
  showSlider: showSliderReducer,
  updateSlider: updateSliderReducer,

  getProduct: getProductReducer,
  createProduct: createProductReducer,
  deleteProduct: deleteProductReducer,
  showProduct: showProductReducer,
  updateProduct: updateProductReduer,

  getSliderProduct: getSliderProductReduer,
  createSliderProduct: createSliderProductReduer,
  deleteSliderProduct: deleteSliderProductReduer,
  showSliderProduct: showSliderProductReduer,
  updateSliderProduct: updateSliderProductReduer,
  showProductSliderByProductId:showProductSliderByProductIdReducer,

  createImageActivity:createImageActivityReducer,
  showImageByActivityId:showImageByActivityIdReducer,
  deleteImageActivity:deleteImageActivityReducer,

  createPDFReduer: createPDFReduer,
  getPdf: getPdfReduer,
  deletePdf:deletePdfReduer,

  createPicture: createPictureReduer,
  getPicture: getPictureReduer,
  deletePicture:deletePictureReduer,

  createBackgrounContact:createBackgrounContactReduer,
  updateBackgrounContact:updateBackgrounContactReduer,
  getBackgrounContact:getBackgrounContactReduer,
  showBackgrounContact:showBackgrounContactReduer,
  deleteBackgrounContact:deleteBackgrounContactReduer,

  createHome:createHomeReducer,
  updateHome:updateHomeReducer,
  getHome:getHomeReducer,
  showHome:showHomeReducer,
  deleteHome:deleteHomeReducer,

  
  getBgActivity:getBgActivityReduer,
  getBgCataloge:getBgCatalogeReducer,
  getBgGallery:getBgGalleryReducer,
  getBgProduct:getBgProductReducer,
  

  
  
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
