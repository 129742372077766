import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";
import {
  createAboutAction,
  shoeAboutAction,
  updateAboutAction,
} from "../../Action/About/aboutAction";
import { useNavigate, useParams } from "react-router-dom";

const EditForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
    image1: null,
    image2: null,
  });

  //   const dispatch=useDispatch();

  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview1, setImagePreview1] = useState(null);
  const [imagePreview2, setImagePreview2] = useState(null);

  const handleChange1 = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview1(reader.result);
        
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleChange2 = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview2(reader.result);
        
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const showAbout = useSelector((state) => state.showAbout);
  const { loading, about } = showAbout;

  useEffect(() => {
    dispatch(shoeAboutAction(id));
  }, []);

  useEffect(() => {
    if (about == undefined) {
      return;
    }
    // console.log("image 1",about.image_content_1)
    setFormData({
      title: about.title || '',
      content_arabic: about.ar_text || '',
      content_turkish: about.tu_text || '',
      content_english: about.en_text || '',
      image: about.image || '',
      image1: about.image_content_1 || '',
      image2: about.image_content_2 || '',
   });
    setImagePreview(about.image);
    setImagePreview1(about.image_content_1);
    setImagePreview2(about.image_content_2);
  }, [about]);
  const handleSubmit = (e) => {
    // console.log(formData.image1)
    e.preventDefault();
    dispatch(
      updateAboutAction(
        id,
        formData.title,
        formData.content_arabic,
        formData.content_english,
        formData.content_turkish,
        formData.image ,
        
      )
    );
  };
  const navigate = useNavigate();
  return (
    <div className="container mx-auto mt-8">
      <form
        onSubmit={handleSubmit}
        className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
      >
        <div className="flex  gap-2 items-center">
          <div
            onClick={() => navigate(-1)}
            className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          >
            Back
          </div>


          {/* <img src={imagePreview} alt="" /> */}
          <div>
            <h2 className="text-2xl font-semibold ">Edit About Form</h2>
          </div>
        </div>

   
        <label
          htmlFor="content_arabic"
          className="block text-gray-700 text-sm font-bold mt-4 mb-2"
        >
          Content (Arabic):
        </label>
        <textarea
          id="content_arabic"
          name="content_arabic"
          value={formData.content_arabic}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        ></textarea>

        <label
          htmlFor="content_english"
          className="block text-gray-700 text-sm font-bold mt-4 mb-2"
        >
          Content (English):
        </label>
        <textarea
          id="content_english"
          name="content_english"
          value={formData.content_english}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        ></textarea>

        <label
          htmlFor="content_turkish"
          className="block text-gray-700 text-sm font-bold mt-4 mb-2"
        >
          Content (Turkish):
        </label>
        <textarea
          id="content_turkish"
          name="content_turkish"
          value={formData.content_turkish}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        ></textarea>

        <label
          htmlFor="image"
          className="block text-gray-700 text-sm font-bold mt-4 mb-2"
        >
          Image:
        </label>
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />

        {imagePreview && (
          <div className="mt-2">
            <img
              src={imagePreview}
              alt="Image Preview"
              className="max-w-full h-auto"
            />
          </div>
        )}


        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditForm;
