export const CREATE_SLIDE_GALLERY_REQUEST = "CREATE_SLIDE_GALLERY_REQUEST";
export const CREATE_SLIDE_GALLERY_SUCCESS = "CREATE_SLIDE_GALLERY_SUCCESS";
export const CREATE_SLIDE_GALLERY_FAIL = "CREATE_SLIDE_GALLERY_FAIL";

export const FETCH_SLIDE_GALLERY_REQUEST = "FETCH_SLIDE_GALLERY_REQUEST";
export const FETCH_SLIDE_GALLERY_SUCCESS = "FETCH_SLIDE_GALLERY_SUCCESS";
export const FETCH_SLIDE_GALLERY_FAIL = "FETCH_SLIDE_GALLERY_FAIL";

export const DELETE_SLIDE_GALLERY_REQUEST = "  DELETE_SLIDE_GALLERY_REQUEST";
export const DELETE_SLIDE_GALLERY_SUCCESS = "  DELETE_SLIDE_GALLERY_SUCCESS";
export const DELETE_SLIDE_GALLERY_FAIL = " DELETE_SLIDE_GALLERY_FAIL";

export const SHOW_SLIDE_GALLERY_REQUEST = "  SHOW_SLIDE_GALLERY_REQUEST";
export const SHOW_SLIDE_GALLERY_SUCCESS = "  SHOW_SLIDE_GALLERY_SUCCESS";
export const SHOW_SLIDE_GALLERY_FAIL = " SHOW_SLIDE_GALLERY_FAIL";

export const UPDATE_SLIDE_GALLERY_REQUEST = "  UPDATE_SLIDE_GALLERY_REQUEST";
export const UPDATE_SLIDE_GALLERY_SUCCESS = "  UPDATE_SLIDE_GALLERY_SUCCESS";
export const UPDATE_SLIDE_GALLERY_FAIL = " UPDATE_SLIDE_GALLERY_FAIL";
