import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import {
  showGalleryAction,
  updateGalleryAction,
} from "../../Action/GalleryAction/GalleryAction";

const EditFormGallery = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
  });

  //   const dispatch=useDispatch();

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const showGallery = useSelector((state) => state.showGallery);
  const { loading, datas } = showGallery;

  useEffect(() => {
    dispatch(showGalleryAction(id));
  }, []);

  useEffect(() => {
    if (datas == undefined) {
      return;
    }

    setImagePreview(datas.image);
  }, [datas]);
  
  const [errorImage, setErrorImage] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.image == null || formData.image == "") {
      setErrorImage("Please Fill the Gap");

      return;
    }



    setErrorImage("");
    
    dispatch(
      updateGalleryAction(
        id,
    
        formData.image
      )
    );
  };
  const navigate = useNavigate();
  return (
    <>
      {datas == undefined || loading ? (
        ""
      ) : (
        <div className="container mx-auto mt-8">
          <form
            onSubmit={handleSubmit}
            className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
          >
            <div className="flex  gap-2 items-center">
              <div
                onClick={() => navigate(-1)}
                className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
              >
                Back
              </div>
              <div>
                <h2 className="text-2xl font-semibold ">Edit Gallery Form</h2>
              </div>
            </div>

       
            <label
              htmlFor="image"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Background Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
            <div>
              <p className="text-lg text-red-300">{errorImage}</p>
            </div>
            {imagePreview && (
              <div className="mt-2">
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  className="w-full object-cover h-52"
                />
              </div>
            )}

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditFormGallery;
