import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./Components/Pages/Sidebar";
import Login from "./Components/Pages/Login";
import About from "./Components/Pages/About/About";
import FormAbout from "./Components/Pages/About/FormAbout";
import EditForm from "./Components/Pages/About/EditForm";
import Gallery from "./Components/Pages/Gallery/Gallery";
import GalleryForm from "./Components/Pages/Gallery/GalleryForm";
import EditFormGallery from "./Components/Pages/Gallery/EditFormGallery";
import Cataloge from "./Components/Pages/Cataloge/Cataloge";
import CatalogeForm from "./Components/Pages/Cataloge/CatalogeForm";
import EditCatalogeForm from "./Components/Pages/Cataloge/EditCatalogeForm";
import Activity from "./Components/Pages/Activity/Activity";
import ActivityForm from "./Components/Pages/Activity/ActivityForm";
import EditActivityForm from "./Components/Pages/Activity/EditActivityForm";
import Slider from "./Components/Pages/Slider/Slider";
import SliderForm from "./Components/Pages/Slider/SliderForm";
import EditFormSlider from "./Components/Pages/Slider/EditFormSlider";
import Product from "./Components/Pages/Products/Product";
import ProductForm from "./Components/Pages/Products/ProductForm";
import EditProductForm from "./Components/Pages/Products/EditProductForm";
import Pdf from "./Components/Pages/Pdf/Pdf";
import Picture from "./Components/Pages/Picture/Picture";
import Home from "./Components/Pages/Home";
import Password from "./Components/Pages/Password/Password";
import ShowSlideGallery from "./Components/Pages/Gallery/ShowSlideGallery";
import ShowDetailsImageProduct from "./Components/Pages/Products/ShowDetailsImageProduct";
import ShowPdf from "./Components/Pages/Cataloge/ShowPdf";
import ShowPicture from "./Components/Pages/Cataloge/ShowPicture";
import Contact from "./Components/Pages/Contact/Contact";
import ContactForm from "./Components/Pages/Contact/ContactForm";
import EditContactForm from "./Components/Pages/Contact/EditContactForm";
import HomeLink from "./Components/Pages/Home/HomeLink";
import FormHome from "./Components/Pages/Home/FormHome";
import EditFormHome from "./Components/Pages/Home/EditFormHome";
import BgCataloge from "./Components/Pages/Cataloge/BgCataloge";
import BgActivity from "./Components/Pages/Activity/BgActivity";
import BgGallery from "./Components/Pages/Gallery/BgGallery";
import BgProduct from "./Components/Pages/Products/BgProduct";
import ProductContentByProductId from "./Components/Pages/Products/ProductContentByProductId";
import EditProductContentByProductId from "./Components/Pages/Products/EditProductContentByProductId";
import ImageActivity from "./Components/Pages/Activity/ImageActivity";
const App = () => {
  const defaultToken = sessionStorage.getItem("tokendefault22330998@34#");
  const [pass, setPass] = useState(null);
  useEffect(() => {
    setPass(defaultToken);
  }, [defaultToken]);

  return (
    <div className="h-full">
      <BrowserRouter>
        {defaultToken || pass ? (
          <div className="flex">
            <div>
              <Sidebar />
            </div>
            <div className="h-[100vh] overflow-y-auto w-full">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/password" element={<Password />} />
                <Route path="/slider" element={<Slider />} />
                <Route path="/about" element={<About />} />
                <Route path="/sliderForm" element={<SliderForm />} />
                <Route path="/slider/edit/:id" element={<EditFormSlider />} />
                <Route path="/formAbout" element={<FormAbout />} />
                <Route path="/editForm/:id" element={<EditForm />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/galleryForm" element={<GalleryForm />} />
                <Route path="/gallery/edit/:id" element={<EditFormGallery />} />
                <Route path="/bgGallery" element={<BgGallery />} />
                <Route path="/cataloge" element={<Cataloge />} />
                <Route path="/catalogeForm" element={<CatalogeForm />} />
                <Route
                  path="/cataloge/edit/:id"
                  element={<EditCatalogeForm />}
                />

                <Route path="/activity" element={<Activity />} />
                <Route path="/activityForm" element={<ActivityForm />} />
                <Route
                  path="/activity/edit/:id"
                  element={<EditActivityForm />}
                />
                <Route path="/bgActivity" element={<BgActivity />} />

                <Route path="/imageActivity/:id" element={<ImageActivity />} />

                <Route path="/product" element={<Product />} />
                <Route path="/productForm" element={<ProductForm />} />
                <Route path="/product/edit/:id" element={<EditProductForm />} />
                <Route path="/bgProduct" element={<BgProduct />} />
                <Route path="product/contetnByProductId/:id" element={<ProductContentByProductId />} />
                <Route path="product/contetnByProductId/edit/:id" element={<EditProductContentByProductId />} />
                

                <Route path="/pdf" element={<Pdf />} />
                <Route path="/picture" element={<Picture />} />

                <Route
                  path="/showSlideGallery"
                  element={<ShowSlideGallery />}
                />
                {/* ShowDetailsImageProduct */}
                <Route
                  path="/ShowDetailsImageProduct"
                  element={<ShowDetailsImageProduct />}
                />
                <Route path="/showPdf/:id" element={<ShowPdf />} />
                <Route path="/showPicture/:id" element={<ShowPicture />} />

                <Route path="/contact" element={<Contact />} />
                <Route path="/ContactForm" element={<ContactForm />} />
                <Route path="/contact/edit/:id" element={<EditContactForm />} />

                <Route path="/Home" element={<HomeLink />} />
                <Route path="/formHome" element={<FormHome />} />
                <Route path="/home/edit/:id" element={<EditFormHome />} />

                <Route path="/bgCataloge" element={<BgCataloge />} />
              </Routes>
            </div>
          </div>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;

