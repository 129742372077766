import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createBgGalleryAction, getBgGalleryAction, updateBgGalleryAction } from "../../Action/GalleryAction/BgGalleryAction";
import { createBgProductAction, getBgProductAction, updateBgProductAction } from "../../Action/ProductAction/BgProductAction";
import { useNavigate } from "react-router-dom";

const BgProduct = () => {
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (id === null || id === "" || id === undefined) {
      dispatch(createBgProductAction(image));
      dispatch(getBgProductAction());
      dispatch(getBgProductAction());
      return;
    } else {
      dispatch(updateBgProductAction(id, image));
      dispatch(getBgProductAction());
      dispatch(getBgProductAction());
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      // Set the selected image to state
      setImage(selectedImage);

      // Display a preview of the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const getBgProduct = useSelector((state) => state.getBgProduct);
  const { Products } = getBgProduct;

  useEffect(() => {
    dispatch(getBgProductAction());
  }, []);

  useEffect(() => {
    
    if (Products == undefined || Products.length == 0) {
      return;
    }
    // Assuming you want the ID of the first cataloge item, adjust as needed
    
    setId(Products[0].id);
    setImagePreview(Products[0].bg_image)
  }, [Products]);
  const navigate=useNavigate()
  return (
    <div className="bg-white m-auto mt-2 w-full lg:w-11/12 rounded-md shadow-sm p-5">
      <div
        onClick={() => navigate(-1)}
        className="bg-blue-500 cursor-pointer lg:w-1/12 text-center mb-4 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      >
        Back
      </div>
      <form action="" className="flex flex-col">
        <input
          type="file"
          onChange={handleImageChange}
          className="border-2 rounded-md border-black p-2"
        />
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            className="mt-2 rounded-md border-2 border-black p-2"
          />
        )}
        <input
          type="submit"
          onClick={handleSubmit}
          className="bg-indigo-500 mt-4 cursor-pointer hover:bg-indigo-600 rounded-md shadow-md px-2 py-2 text-white duration-500"
        />
      </form>
    </div>
  );
};

export default BgProduct;
