import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import {
  getSliderAction,
  showSliderAction,
  updateSliderAction,
} from "../../Action/SliderAction/SliderAction";

const EditFormSlider = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    image: null,
  });

  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const showSlider = useSelector((state) => state.showSlider);
  const { loading, datas } = showSlider;

  useEffect(() => {
    dispatch(showSliderAction(id));
  }, []);

  useEffect(() => {
    console.log(datas)
    if (datas == undefined) {
      return;
    }
    setFormData({
      image: datas.image,
    });
    setImagePreview(datas.image);
  }, [datas]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSliderAction(id, formData.image));
    
    // navigate("/")
  };

  return (
    <>
      {datas == undefined || loading ? (
        ""
      ) : (
        <div className="container mx-auto mt-8">
          <form
            // onSubmit={handleSubmit}
            className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
          >
            <div className="flex  gap-2 items-center">
              <div
                onClick={() => navigate(-1)}
                className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
              >
                Back
              </div>
              <div>
                <h2 className="text-2xl font-semibold ">Edit Slider Form</h2>
              </div>
            </div>

            <label
              htmlFor="image"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />

            {imagePreview && (
              <div className="mt-2">
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  className="object-cover w-full h-[20rem]"
                />
              </div>
            )}

            <button
              type="submit"
              onClick={handleSubmit}
              className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditFormSlider;
