import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import {
  createSlideGalleryAction,
  deleteSlideGalleryAction,
  getSlideGalleryAction,
  updateSlideGalleryAction,
} from "../../Action/GalleryAction/GallerySliderAction";
import { FaPlus, FaTrash } from "react-icons/fa6";
import Swal from "sweetalert2";
const ShowSlideGallery = () => {
  const getSlideGallery = useSelector((state) => state.getSlideGallery);
  const { slideGallerys, loading, total } = getSlideGallery;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getSlideGalleryAction());
    // console.log(total)
  }, []);
  const [indexx, setIndexx] = useState(null);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const handleUpdate = () => {
    if (image == undefined || image == "" || image == null) {
      setIndexx(null);
      return;
    } else {
      dispatch(updateSlideGalleryAction(id, image));
      dispatch(getSlideGalleryAction(1, 10));
      dispatch(getSlideGalleryAction(1, 10));
      dispatch(getSlideGalleryAction(1, 10));
      dispatch(getSlideGalleryAction(1, 10));
      setImage(null);

      setIndexx(null);
    }
  };

  const handelInputChange = (event, index) => {
    const newValue = [...imageSlide];
    newValue[index] = event.target.files[0];
    setImageSlide(newValue);
  };

  const [isOpenModalSlide, setIsOpenModalSlide] = useState(false);
  const [imageSlide, setImageSlide] = useState([""]);
  const handelDelete = (index) => {
    const deleteValue = [...imageSlide];
    deleteValue.splice(index, 1);
    setImageSlide(deleteValue);
  };
  const handleSubmit=()=>{
    if(imageSlide.length==0){
      return;
    }
    dispatch(createSlideGalleryAction(imageSlide))

    dispatch(getSlideGalleryAction(1, 10));
    dispatch(getSlideGalleryAction(1, 10));
    dispatch(getSlideGalleryAction(1, 10));
    dispatch(getSlideGalleryAction(1, 10));
    setIsOpenModalSlide(false)
    Swal.fire({
      icon:"success",
      timer:1500,
      title:"Goode Job",
      text:"Information was save"
    })
  }
  return (
    <>
      {loading || slideGallerys == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div>
            <button
              onClick={() => setIsOpenModalSlide(true)}
              className="ml-3 bg-blue-500 hover:bg-blue-600 rounded-md shadow-md px-2 py-2 duration-500 text-white mt-2"
            >
              Addnew
            </button>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Picture
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {slideGallerys.map((gallery, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {indexx == null ? (
                              <img
                                src={gallery.image}
                                className="w-full object-cover h-40"
                                alt=""
                              />
                            ) :indexx==index? (
                              <input
                                type="file"
                                className="input border-2 p-1 rounded-md shadow-md  "
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                            ):""}
                          </td>
                          {indexx == null ? (
                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                              <a
                                className="text-green-500 cursor-pointer hover:text-green-700"
                                onClick={() => {
                                  setIndexx(index);
                                  setId(gallery.id);
                                }}
                              >
                                Edit
                              </a>
                            </td>
                          ) : indexx==index? (
                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                              <a
                                className="text-green-500 cursor-pointer hover:text-green-700"
                                onClick={() => handleUpdate()}
                              >
                                Update
                              </a>
                            </td>
                          ):""}

                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(gallery.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end mr-2">
        <ReactPaginate
          previousLabel="<"
          nextLabel={">"}
          // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
          breakLabel="..."
          className="flex  items-center   -space-x-px h-8 text-sm"
          previousClassName="flex bg-white items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
          nextClassName="flex bg-white items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
          pageLinkClassName="flex  items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
          breakClassName="w-8 py-2 text-center"
          pageCount={Math.ceil(total / 10)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          // activeClassName=''
          onPageChange={(event) => {
            let currentPage = event.selected + 1;
            setPage(currentPage);
            dispatch(getSlideGalleryAction(currentPage, 10));
          }}
          activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
        />
      </div>

      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteSlideGalleryAction(id));
                  dispatch(getSlideGalleryAction());
                  dispatch(getSlideGalleryAction());
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpenModalSlide && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to Add Picture?
            </p>
            <div className="mt-10">
              <div className=" flex justify-end">
                <div
                  onClick={() => setImageSlide([...imageSlide, ""])}
                  className=" bg-teal-500 hover:bg-teal-700 px-3 py-2 cursor-pointer duration-500 rounded-sm shadow-md text-white m-2"
                >
                  <FaPlus />
                </div>
              </div>
              {imageSlide.map((image, index) => {
                return (
                  <div className="flex w-full gap-3 items-center">
                    <input
                      onChange={(e) => {
                        handelInputChange(e, index);
                      }}
                      type="file"
                      className="input border-2 mt-2 w-full p-2 rounded-md shadow-sm "
                    />
                    <div
                      onClick={() => handelDelete(index)}
                      className="bg-red-500 hover:bg-red-700 px-3 py-2 cursor-pointer duration-500 rounded-sm shadow-md text-white m-2"
                    >
                      <FaTrash />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex gap-3">
              <button
              onClick={handleSubmit}
               className="bg-teal-500 hover:bg-teal-600 text-white mt-2 duration-500 rounded-md shadow-md px-5 py-2 ">
                Save
              </button>
              <button 
              onClick={()=>setIsOpenModalSlide(false)}
               className="bg-red-500 hover:bg-red-600 text-white mt-2 duration-500 rounded-md shadow-md px-5 py-2 ">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowSlideGallery;
