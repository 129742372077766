import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import { deleteGalleryAction, getGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import { IoMdDocument } from 'react-icons/io'
import {

  showCatalogeAction,
} from "../../Action/CatalogeAction/CatalogeAction";
import {
  createPdfAction,
  deletePdfAction,
  getPdfAction,
} from "../../Action/PdfAction/pdfAction";
import { createPictureAction } from "../../Action/PictureAction/PictureAction";
const ShowPdf = () => {
  const { id } = useParams();
  const showCataloge = useSelector((state) => state.showCataloge);
  const { datas, loading, total } = showCataloge;
  const [pdf, setPdf] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [idd, setIdd] = useState(null);
  useEffect(() => {
    dispatch(showCatalogeAction(id));
    // console.log(total)
  }, []);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [isPdfModal, setIsPdfModa] = useState(false);
  const [isPictureModal, setIsPictureModa] = useState(false);

  //   const [id, setId] = useState(null);
  const navigate = useNavigate();
  const [picture, setPicture] = useState([]);
  const handelInputChange = (event, index) => {
    const newValue = [...picture];
    newValue[index] = event.target.files[0];
    // console.log(newValue)
    setPicture(newValue);
  };

  const [errorPicture, setErrorPicture] = useState("");
  return (
    <>
      {loading || datas == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div
        onClick={() => navigate(-1)}
        className="bg-blue-500 cursor-pointer lg:w-1/12 text-center mb-4 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
      >
        Back
      </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="overflow-hidden border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        PDF
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {datas.map((data1, index) => {
                      return data1.pdfs.map((data, index2) => {
                        return (
                          <tr key={index}>
                            <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                              {index + 1}
                            </td>
                            <td className="px-6  w-20 text-2xl py-4  text-gray-800 whitespace-nowrap">
                              <Link to={data.pdf}>
                              <IoMdDocument />
                              </Link>
                            </td>

                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                              <a
                                className="text-red-500 hover:text-red-700"
                                href="#"
                                onClick={() => {
                                  setIsDeleteModalView(true);
                                  setIdd(data.id);
                                }}
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deletePdfAction(idd));
                  dispatch(showCatalogeAction(id));
                  dispatch(showCatalogeAction(id));
                  dispatch(showCatalogeAction(id));

                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowPdf;
