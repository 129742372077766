import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import {
  //   showGalleryAction,
  updateGalleryAction,
} from "../../Action/GalleryAction/GalleryAction";
import {
  shoeCatalogeAction,
  showCatalogeAction,
  updateCatalogeAction,
} from "../../Action/CatalogeAction/CatalogeAction";

const EditCatalogeForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
  });

  //   const dispatch=useDispatch();

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const showCataloge = useSelector((state) => state.showCataloge);
  const { loading, datas } = showCataloge;

  useEffect(() => {
    dispatch(showCatalogeAction(id));
  }, []);

  useEffect(() => {
    if (datas == undefined) {
      return;
    }
    // console.log(datas)
    datas.map((data)=>{
      setFormData({
        title: data.title,
        content_arabic: data.ar_text,
        content_turkish: data.tu_text,
        content_english: data.en_text,
        image: data.image,
      });
      setImagePreview(data.image);
    })
    
    
  }, [datas]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateCatalogeAction(
        id,
        formData.title,
        formData.content_arabic,
        formData.content_english,
        formData.content_turkish,
        formData.image
      )
    );
  };
  const navigate = useNavigate();
  return (
    <>
      {datas == undefined || loading ? (
        ""
      ) : (
        <div className="container mx-auto mt-8">
          <form
            onSubmit={handleSubmit}
            className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
          >
            <div className="flex  gap-2 items-center">
              <div
                onClick={() => navigate(-1)}
                className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
              >
                Back
              </div>
              <div>
                <h2 className="text-2xl font-semibold ">Edit Cataloge Form</h2>
              </div>
            </div>
{/* 
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            /> */}

            <label
              htmlFor="content_arabic"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Title (Arabic):
            </label>
            <textarea
              id="content_arabic"
              name="content_arabic"
              value={formData.content_arabic}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>

            <label
              htmlFor="content_english"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Title (English):
            </label>
            <textarea
              id="content_english"
              name="content_english"
              value={formData.content_english}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>

            <label
              htmlFor="content_turkish"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Title (Turkish):
            </label>
            <textarea
              id="content_turkish"
              name="content_turkish"
              value={formData.content_turkish}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>

            <label
              htmlFor="image"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />

            {imagePreview && (
              <div className="mt-2">
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  className="w-full h-44  object-cover"
                />
              </div>
            )}

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditCatalogeForm;
