import { CREATE_BG_ACTIVITY_FAIL, CREATE_BG_ACTIVITY_REQUEST, CREATE_BG_ACTIVITY_SUCCESS, DELETE_BG_ACTIVITY_FAIL, DELETE_BG_ACTIVITY_REQUEST, DELETE_BG_ACTIVITY_SUCCESS, FETCH_BG_ACTIVITY_FAIL, FETCH_BG_ACTIVITY_REQUEST, FETCH_BG_ACTIVITY_SUCCESS, SHOW_BG_ACTIVITY_FAIL, SHOW_BG_ACTIVITY_REQUEST, SHOW_BG_ACTIVITY_SUCCESS, UPDATE_BG_ACTIVITY_FAIL, UPDATE_BG_ACTIVITY_REQUEST, UPDATE_BG_ACTIVITY_SUCCESS } from "../ActionType/ActivityType/bgActivityType";

  export const createBgActivityReduer = (
    state = { loading: true, activity: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_BG_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case CREATE_BG_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activity: action.payload,
          error: "",
        };
      case CREATE_BG_ACTIVITY_FAIL:
        return {
          loading: false,
          activity: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const getBgActivityReduer = (
    state = { loading: true, activitys: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_BG_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case FETCH_BG_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activitys: action.payload,
          total: action.total,
          error: "",
        };
      case FETCH_BG_ACTIVITY_FAIL:
        return {
          loading: false,
          activitys: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const deleteBgActivitytReduer = (
    state = { loading: true, activity: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_BG_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case DELETE_BG_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activity: action.payload,
          error: "",
        };
      case DELETE_BG_ACTIVITY_FAIL:
        return {
          loading: false,
          activity: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const showBgActivitytReduer = (
    state = { loading: true, activity: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_BG_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case SHOW_BG_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activity: action.payload,
          error: "",
        };
      case SHOW_BG_ACTIVITY_FAIL:
        return {
          loading: false,
          activity: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  
  export const updateBgActivitytReduer = (
    state = { loading: true, activity: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_BG_ACTIVITY_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_BG_ACTIVITY_SUCCESS:
        return {
          loading: false,
          activity: action.payload,
          error: "",
        };
      case UPDATE_BG_ACTIVITY_FAIL:
        return {
          loading: false,
          activity: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };
  