import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAction, getUserAuthAction } from "../../Action/login";

const Password = () => {
  const dispatch = useDispatch();
  const getUserAuth = useSelector((state) => state.getUserAuth);
  const { user } = getUserAuth;
  useEffect(() => {
    dispatch(getUserAuthAction());
  }, []);
  const [pass, setPass] = useState(null);
  const [confPass, setConfPass] = useState(null);
  const [error,setError]=useState();
  const handleSubmit=(event)=>{
    event.preventDefault();
    if(pass!=confPass){
        setError("Please Write a Correct Password")
        return
    }
    else{
        dispatch(changePasswordAction(user.id,pass))
        setError("")
    }
  }
  return (
    <div className="bg-white lg:w-11/12 p-20 ml-16 mt-10 m-auto ">
      <form action="">
        <div className=" flex flex-col">
          <label htmlFor="">New Password *</label>
          <input
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="New Password"
            className="input  focus:border-blue-300 outline-none border-2 p-2 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="">Confirm Password *</label>
          <input
            onChange={(e) => setConfPass(e.target.value)}
            type="password"
            placeholder="Confirm Password"
            className="input  focus:border-blue-300 outline-none border-2 p-2 rounded-md"
          />
        </div>
        <div >
            <p className="text-lg text-red-500">{error}</p>
        </div>
        <button onClick={handleSubmit} className="bg-blue-500 p-2 rounded-md shadow-md  text-white mt-4">
          Change
        </button>
      </form>
    </div>
  );
};

export default Password;
