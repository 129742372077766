import {
  DELETE_GALLERY_FAIL,
  DELETE_GALLERY_SUCCESS,
  FETCH_GALLERY_FAIL,
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
  SHOW_GALLERY_FAIL,
  SHOW_GALLERY_REQUEST,
  SHOW_GALLERY_SUCCESS,
} from "../../ActionType/GallaryType/GallaryType";
import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import { createSlideGalleryAction } from "./GallerySliderAction";

export const getGalleryAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_GALLERY_REQUEST,
  });
  api
    .get("gallery", config)
    .then((res) => {
      dispatch({
        type: FETCH_GALLERY_SUCCESS,
        payload: res.data.data,
        total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_GALLERY_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createGalleryAction =
  ( image) => (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    
    for (let i = 0; i < image.length; i++) {
      console.log(image[i])
      formData.append(`image[]`, image[i])
    }
    console.log(image)

    api
      .post("gallery/create", formData, config)
      .then((res) => {
        console.log(res)
        // dispatch(createSlideGalleryAction( sliderImage));
        if (res.status == 200) {
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };

export const deleteGalleryAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .delete(`gallery/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_GALLERY_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_GALLERY_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showGalleryAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_GALLERY_REQUEST,
    // payload: res.data,
  });
  api
    .get(`gallery/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_GALLERY_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_GALLERY_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateGalleryAction =
  (id,  image) => (dispatch) => {
    // console.log(id,title, ar_text,tu_text,en_text, image)
    const formData = new FormData();
  
    formData.append("image", image);
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .post(`gallery/update/${id}`, formData, config)
      .then((res) => {
        //  console.log(res)
        if (res.status == 200) {
          // console.log(res)
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_GALLERY_FAIL,
          // payload: res.data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };
