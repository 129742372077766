import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  showSliderProductAction,
  updateSliderProductAction,
} from "../../Action/ProductAction/ProductSliderAction";

const EditProductContentByProductId = () => {
  const [formData, setFormData] = useState({
    title_ar: "",
    title_en: "",
    title_tu: "",
    text_ar: "",
    text_en: "",
    text_turkish: "",
    img:"",
    productId:"",
  });

  const handleChangeFile=(event)=>{
    const files=event.target.files[0];
    setFormData({
        ...formData,
        img:files
    })
  }
  const { id } = useParams();
  const showSliderProduct = useSelector((state) => state.showSliderProduct);
  const { datas } = showSliderProduct;
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showSliderProductAction(id));
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
  
    dispatch(
      updateSliderProductAction(
        id,
        formData.title_ar,
        formData.title_en,
        formData.title_tu,
        formData.text_ar,
        formData.text_en,
        formData.text_turkish,
        formData.img,
        formData.productId,
      )
    );
    // Add your logic for form submission, e.g., API call or further processing
  };

  useEffect(() => {
    if (datas === undefined || datas === null) {
      return;
    }

    setFormData({
      ...formData,
      title_ar: datas.title_ar || "",
      title_en: datas.title || "",
      title_tu: datas.title_tu || "",
      text_ar: datas.ar_text || "",
      text_en: datas.en_text || "",
      text_turkish: datas.tu_text || "",
      img: datas.image || "",
      productId: datas.product_id || "",
    });
  }, [datas]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded shadow-md  w-11/12">
        <h2 className="text-2xl font-bold mb-6">Edit Form</h2>
        <form >
          {/* Title Fields */}
          {["ar", "en", "tu"].map((lang) => (
            <div key={`title_${lang}`} className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={`title_${lang}`}
              >
                Title (
                {lang === "ar"
                  ? "Arabic"
                  : lang === "en"
                  ? "English"
                  : "Turkish"}
                )
              </label>
              <input
                type="text"
                id={`title_${lang}`}
                name={`title_${lang}`}
                value={formData[`title_${lang}`]}
                onChange={handleInputChange}
                className="border rounded w-full py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
          ))}

          {/* Text Fields */}
          {["ar", "en", "turkish"].map((lang) => (
            <div key={`text_${lang}`} className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={`text_${lang}`}
              >
                Text (
                {lang === "ar"
                  ? "Arabic"
                  : lang === "en"
                  ? "English"
                  : "Turkish"}
                )
              </label>
              <textarea
                id={`text_${lang}`}
                name={`text_${lang}`}
                value={formData[`text_${lang}`]}
                onChange={handleInputChange}
                className="border rounded w-full py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
              />
            </div>
          ))}

          <div>
            <input type="file" className="border-2 " name="img" onChange={handleChangeFile}  />
          </div>

          {/* Submit Button */}
          <div className="mb-4 mt-2">
            <button
            onClick={handleSubmit}
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProductContentByProductId;
