import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  CREATE_BG_PRODUCT_FAIL,
  CREATE_BG_PRODUCT_REQUEST,
  CREATE_BG_PRODUCT_SUCCESS,
  DELETE_BG_PRODUCT_FAIL,
  DELETE_BG_PRODUCT_SUCCESS,
  FETCH_BG_PRODUCT_FAIL,
  FETCH_BG_PRODUCT_REQUEST,
  FETCH_BG_PRODUCT_SUCCESS,
  SHOW_BG_PRODUCT_FAIL,
  SHOW_BG_PRODUCT_REQUEST,
  SHOW_BG_PRODUCT_SUCCESS,
  UPDATE_BG_PRODUCT_FAIL,
  UPDATE_BG_PRODUCT_REQUEST,
  UPDATE_BG_PRODUCT_SUCCESS,
} from "../../ActionType/ProductType/bgProductType";

export const getBgProductAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_BG_PRODUCT_REQUEST,
  });
  api
    .get("bgProduct", config)
    .then((res) => {
      dispatch({
        type: FETCH_BG_PRODUCT_SUCCESS,
        payload: res.data,
        total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_BG_PRODUCT_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createBgProductAction = (image) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();

  formData.append("bg_image", image);

  dispatch({
    type: CREATE_BG_PRODUCT_REQUEST,
  });

  api
    .post("bgProduct/create", formData, config)
    .then((res) => {
      if (res.status == 200) {
        
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
        dispatch({
          type: CREATE_BG_PRODUCT_SUCCESS,
          payload: res.data,
        });
        // window.location.reload()
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      dispatch({
        type: CREATE_BG_PRODUCT_FAIL,
      });
    });
};

export const deleteBgProductAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .delete(`bgProduct/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_BG_PRODUCT_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_BG_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showBgProductAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_BG_PRODUCT_REQUEST,
    // payload: res.data,
  });
  api
    .get(`product/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_BG_PRODUCT_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_BG_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateBgProductAction = (id, image) => (dispatch) => {
  const formData = new FormData();

  formData.append("bg_image", image);
  dispatch({
    type: UPDATE_BG_PRODUCT_REQUEST,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`bgProduct/update/${id}`, formData, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: UPDATE_BG_PRODUCT_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Updated",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_BG_PRODUCT_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};
