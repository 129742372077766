import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import {
  shoeCatalogeAction,
  showCatalogeAction,
  updateCatalogeAction,
} from "../../Action/CatalogeAction/CatalogeAction";
import {
  showActivityAction,
  updateActivityAction,
} from "../../Action/ActivityAction/ActivityAction";

const EditActivityForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title_en: "",
    title_tu: "",
    title_ar: "",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
  });

  //   const dispatch=useDispatch();

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const showActivity = useSelector((state) => state.showActivity);
  const { loading, activity } = showActivity;

  useEffect(() => {
    dispatch(showActivityAction(id));
  }, []);

  useEffect(() => {
    if (activity == undefined) {
      return;
    }
    // console.log(datas)
    setFormData({
      title_en: activity.title,
      title_ar: activity.title_ar,
      title_tu: activity.title_tu,
      content_arabic: activity.ar_text,
      content_turkish: activity.tu_text,
      content_english: activity.en_text,
      image: activity.image,
    });
    setImagePreview(activity.image);
  }, [activity]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateActivityAction(
        id,
        formData.title_en,
        formData.title_ar,
        formData.title_tu,
        formData.content_arabic,
        formData.content_english,
        formData.content_turkish,
        formData.image
      )
    );
  };
  const navigate=useNavigate();
  return (
    <>
      {activity == undefined || loading ? (
        ""
      ) : (
        <div className="container mx-auto mt-8">
          <form
            onSubmit={handleSubmit}
            className="w-[95%] md:w-10/12 lg:w-9/12  mx-auto bg-white p-8 rounded-md shadow-md"
          >
            <div className="flex  gap-2 items-center">
              <div
                onClick={() => navigate(-1)}
                className="bg-blue-500 cursor-pointer hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
              >
                Back
              </div>
              <div>
                <h2 className="text-2xl font-semibold ">Edit Slider Form</h2>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3">
          <div>
            <div>
              <label
                htmlFor="title"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Title English:
              </label>
              <input
                type="text"
                id="title"
                name="title_en"
                value={formData.title_en}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="title"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Title Arabic:
              </label>
              <input
                type="text"
                id="title"
                name="title_ar"
                value={formData.title_ar}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label
                htmlFor="title"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Title Turkish:
              </label>
              <input
                type="text"
                id="title"
                name="title_tu"
                value={formData.title_tu}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div>
            <div>
              <label
                htmlFor="content_arabic"
                className="block text-gray-700 text-sm font-bold  mb-2"
              >
                Content (Arabic):
              </label>

              <textarea
                rows={1}
                id="content_arabic"
                name="content_arabic"
                value={formData.content_arabic}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              ></textarea>
            </div>

            <div>
              <label
                htmlFor="content_english"
                className="block text-gray-700 text-sm font-bold mb-0.5 "
              >
                Content (English):
              </label>
              <textarea
                rows={1}
                id="content_english"
                name="content_english"
                value={formData.content_english}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              ></textarea>
            </div>

            <div>
              <label
                htmlFor="content_turkish"
                className="block text-gray-700 text-sm font-bold  mb-0.5"
              >
                Content (Turkish):
              </label>
              <textarea
                rows={1}
                id="content_turkish"
                name="content_turkish"
                value={formData.content_turkish}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              ></textarea>
            </div>
          </div>
        </div>
            <label
              htmlFor="image"
              className="block text-gray-700 text-sm font-bold mt-4 mb-2"
            >
              Image:
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />

            {imagePreview && (
              <div className="mt-2">
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  className="max-w-full h-auto"
                />
              </div>
            )}

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 mt-6 rounded-md"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default EditActivityForm;
