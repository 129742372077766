import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import { deleteGalleryAction, getGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import {
  deleteCatalogeAction,
  getCatalogeAction,
} from "../../Action/CatalogeAction/CatalogeAction";
import {
  deleteActivityAction,
  getActivityAction,
} from "../../Action/ActivityAction/ActivityAction";
import { createImageActivityAction } from "../../Action/ImageActivityAction/ImageActivityAction";
import { FaPlus } from "react-icons/fa6";
const Activity = () => {
  const getActivity = useSelector((state) => state.getActivity);
  const { activitys, loading, total } = getActivity;
  const dispatch = useDispatch();
  const [activityId,setActivityId]=useState(null);
  const [page, setPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    dispatch(getActivityAction(page));
    // console.log(total)
  }, []);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleUpload = () => {
    // onFileSelect(selectedFiles);
    dispatch(createImageActivityAction(activityId,selectedFiles))
    setIsOpenModal(false);
    setSelectedFiles([]);
    // onClose();
  };
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      {loading || activitys == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <button
                onClick={() => navigate("/bgActivity")}
                className="bg-indigo-500 rounded-md shadow-md duration-500 hover:bg-indigo-600 mt-2 p-2 text-white ml-3"
              >
                Add BackgroundImage
              </button>
              <button
                onClick={() => navigate("/activityForm")}
                className="bg-blue-500 rounded-md shadow-md duration-500 hover:bg-blue-600 mt-2 p-2 text-white ml-3"
              >
                Add New
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className=" border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        English
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Turkesh
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Arabic
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Another Image
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {activitys.map((activity, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {activity.en_text.slice(0, 10)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {activity.tu_text.slice(0, 10)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {activity.ar_text.slice(0, 10)}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-center whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("/imageActivity/" + activity.id);
                              }}
                            >
                              Images
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("edit/" + activity.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(activity.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>

                          <td
                            onClick={() => {
                              setIsOpenModal(true);
                              setActivityId(activity.id)
                              
                            }}
                            className="px-6 py-4 flex justify-end text-sm font-medium text-right whitespace-nowrap cursor-pointer text-blue-600"
                          >
                            <p className="text-xl"> <FaPlus/>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteActivityAction(id));
                  dispatch(getActivity(page));
                  dispatch(getActivity(page));
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpenModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="relative bg-white rounded-lg overflow-hidden max-w-md p-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Choose Images:
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  multiple
                  className="appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleUpload}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Upload
                </button>
                <button
                  onClick={() => setIsOpenModal(false)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Activity;
