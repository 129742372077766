import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAboutAction,
  getAboutAction,
} from "../../Action/About/aboutAction";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  deleteHomeAction,
  getHomeAction,
} from "../../Action/HomeAction/HomeAction";
const HomeLink = () => {
  const getHome = useSelector((state) => state.getHome);
  const { Homes, loading, total } = getHome;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getHomeAction());
    // console.log(total)
  }, []);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      {loading || Homes == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div className="flex">
            <div>
              {
                Homes.length>0?"":<button
                onClick={() => {
                  navigate("/formHome");
                }}
                className="bg-blue-500 rounded-md shadow-md duration-500 hover:bg-blue-600 mt-2 p-2 text-white ml-3"
              >
                Add New
              </button>
              }
              
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className=" border rounded-lg">
                <table className="min-w-full  overflow-auto divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        English
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Turkesh
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Arabic
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y  divide-gray-200">
                    {Homes.map((home, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 w-20 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {home.title.slice(0, 40)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {home.title_tu.slice(0, 40)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {home.title_ar.slice(0, 40)}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("/home/edit/" + home.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(home.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end mr-2">
        <ReactPaginate
          previousLabel="<"
          nextLabel={">"}
          // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
          breakLabel="..."
          className="flex  items-center   -space-x-px h-8 text-sm"
          previousClassName="flex bg-white items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
          nextClassName="flex bg-white items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
          pageLinkClassName="flex  items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
          breakClassName="w-8 py-2 text-center"
          pageCount={Math.ceil(10 / 10)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          // activeClassName=''
          onPageChange={(event) => {
            let currentPage = event.selected + 1;
            setPage(currentPage);
            dispatch(getHomeAction(currentPage));
          }}
          activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
        />
      </div>

      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteHomeAction(id));
                  dispatch(getHomeAction());
                  dispatch(getHomeAction());
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeLink;
