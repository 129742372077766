// import { CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, FETCH_PRODUCT_FAIL, FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, SHOW_PRODUCT_FAIL, SHOW_PRODUCT_REQUEST, SHOW_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAIL, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS } from "../ActionType/ProductType/productType";

import { CREATE_SLIDER_PRODUCT_FAIL, CREATE_SLIDER_PRODUCT_REQUEST, CREATE_SLIDER_PRODUCT_SUCCESS, DELETE_SLIDER_PRODUCT_FAIL, DELETE_SLIDER_PRODUCT_REQUEST, DELETE_SLIDER_PRODUCT_SUCCESS, FETCH_SLIDER_PRODUCT_FAIL, FETCH_SLIDER_PRODUCT_REQUEST, FETCH_SLIDER_PRODUCT_SUCCESS, SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_FAIL, SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_REQUEST, SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_SUCCESS, SHOW_SLIDER_PRODUCT_FAIL, SHOW_SLIDER_PRODUCT_REQUEST, SHOW_SLIDER_PRODUCT_SUCCESS, UPDATE_SLIDER_PRODUCT_FAIL, UPDATE_SLIDER_PRODUCT_REQUEST, UPDATE_SLIDER_PRODUCT_SUCCESS } from "../ActionType/ProductType/ProductSliderType";


  
  export const getSliderProductReduer = (
      state = { loading: true, productSliders: [],total:0, error: "" },
      action
    ) => {
      switch (action.type) {
        case FETCH_SLIDER_PRODUCT_REQUEST:
          return {
            loading: true,
          };
        case FETCH_SLIDER_PRODUCT_SUCCESS:

          return {
            loading: false,
            productSliders: action.payload,
            total:action.total,
            error: "",
          };
        case FETCH_SLIDER_PRODUCT_FAIL:
          return {
            loading: false,
            productSliders: [],
            error: "",
          };
        default:
          return state;
      }
    };
  
    
  export const createSliderProductReduer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_SLIDER_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case CREATE_SLIDER_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case CREATE_SLIDER_PRODUCT_FAIL:
        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const deleteSliderProductReduer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_SLIDER_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case DELETE_SLIDER_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case DELETE_SLIDER_PRODUCT_FAIL:

        return {
          loading: false,
          data: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  
  export const showSliderProductReduer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_SLIDER_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case SHOW_SLIDER_PRODUCT_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_SLIDER_PRODUCT_FAIL:
        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateSliderProductReduer = (
    state = { loading: true, data: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_SLIDER_PRODUCT_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_SLIDER_PRODUCT_SUCCESS:
        return {
          loading: false,
          data: action.payload,
          error: "",
        };
      case UPDATE_SLIDER_PRODUCT_FAIL:
        return {
          loading: false,
          data: [],
          error: "Please CHeck your Query",
        };
      default:
        return state;
    }
  };


  export const showProductSliderByProductIdReducer = (
    state = { loading: true, datas: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_REQUEST:
        return {
          loading: true,
        };
      case SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_SUCCESS:
        return {
          loading: false,
          datas: action.payload,
          error: "",
        };
      case SHOW_SLIDER_PRODUCT_BY_PRODUCT_ID_FAIL:

        return {
          loading: false,
          datas: [],
          error: "",
        };
      default:
        return state;
    }
  };
  