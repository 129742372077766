import { api } from "../../Api/api";

import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  DELETE_SLIDE_GALLERY_FAIL,
  DELETE_SLIDE_GALLERY_SUCCESS,
  FETCH_SLIDE_GALLERY_FAIL,
  FETCH_SLIDE_GALLERY_REQUEST,
  FETCH_SLIDE_GALLERY_SUCCESS,
  SHOW_SLIDE_GALLERY_FAIL,
  SHOW_SLIDE_GALLERY_REQUEST,
  SHOW_SLIDE_GALLERY_SUCCESS,
  UPDATE_SLIDE_GALLERY_FAIL,
  UPDATE_SLIDE_GALLERY_SUCCESS,
} from "../../ActionType/GallaryType/SliderGalary";

export const getSlideGalleryAction = (page, perPage) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    
    params: {
      page,
      perPage,
    },
  };
// console.log(page,perPage)
  dispatch({
    type: FETCH_SLIDE_GALLERY_REQUEST,
  });
  api
    .get("gallerySlider", config)
    .then((res) => {
      dispatch({
        type: FETCH_SLIDE_GALLERY_SUCCESS,
        payload: res.data.data,
        total: res.data.total,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_SLIDE_GALLERY_FAIL,
        payload: [],
        error: "",
      });
    });
};

export const createSlideGalleryAction = ( image) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  // console.log(gallery_id);
  const formData = new FormData();
  
  image.map((img) => {
    formData.append("image[]", img);
  });

  api
    .post("gallerySlider/create", formData, config)
    .then((res) => {
      console.log(res);
      if (res.status == 201) {
        console.log("data send", res.data);
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Saved",
        });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const deleteSlideGalleryAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .delete(`gallerySlider/delete/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: DELETE_SLIDE_GALLERY_SUCCESS,
          payload: res.data,
        });
        Swal.fire({
          icon: "success",
          timer: 2000,
          title: "Good Job",
          text: "Information Was Delete",
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: DELETE_SLIDE_GALLERY_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      console.log(err);
    });
};

export const showSlideGalleryAction = (id) => (dispatch) => {
  //   console.log(formData)
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_SLIDE_GALLERY_REQUEST,
    // payload: res.data,
  });
  api
    .get(`gallerySlider/show/${id}`, config)
    .then((res) => {
      if (res.status == 200) {
        dispatch({
          type: SHOW_SLIDE_GALLERY_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SHOW_SLIDE_GALLERY_FAIL,
        // payload: res.data,
      });
      Swal.fire({
        icon: "error",
        timer: 2000,
        title: "Error",
        text: "Please Check Your Request",
      });
      // console.log(err);
    });
};

export const updateSlideGalleryAction =
  (id,  image) => (dispatch) => {
    const formData = new FormData();
    formData.append("image", image);
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .post(`gallerySlider/update/${id}`, formData, config)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: UPDATE_SLIDE_GALLERY_SUCCESS,
            payload: res.data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "Good Job",
            text: "Information Was Saved",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_SLIDE_GALLERY_FAIL,
          // payload: res.data,
        });
        Swal.fire({
          icon: "error",
          timer: 2000,
          title: "Error",
          text: "Please Check Your Request",
        });
        console.log(err);
      });
  };
