import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createHomeAction } from "../../Action/HomeAction/HomeAction";

const FormHome = () => {
  const [formData, setFormData] = useState({
    title_en: "",
    title_ar: "",
    title_tu: "",

    content_arabic: "",
    content_english: "",
    content_turkish: "",
    link: "",
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    
    dispatch(
      createHomeAction(
        formData.title_en,
        formData.title_ar,
        formData.title_tu,
        
        formData.content_english,
        formData.content_arabic,
        formData.content_turkish,
        formData.link


      )
    );
    // Handle form submission here (send data to server, etc.)
  };
  const navigate = useNavigate();
  return (
    <div className="bg-white m-auto w-11/12 py-2 px-4 mt-4 rounded-md">
      <div className="flex gap-3 items-center  mb-5 "> 
        
        <div className="w-1/12">
            <button 
               className="bg-blue-500  text-white hover:bg-blue-600 duration-500 py-1  w-full rounded-sm "
            onClick={()=>navigate(-1)}>
                Back
            </button>
        </div>
        <p className="font-bold">Home Form</p>
      </div>
      <div className="grid  grid-cols-2 gap-3 ">
        <div>
          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Title English:
            </label>
            <input
              type="text"
              id="title"
              name="title_en"
              value={formData.title_en}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Title Arabic:
            </label>
            <input
              type="text"
              id="title"
              name="title_ar"
              value={formData.title_ar}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Title Turkish:
            </label>
            <input
              type="text"
              id="title"
              name="title_tu"
              value={formData.title_tu}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
        </div>
        <div>
        <div>
            <label
              htmlFor="content_english"
              className="block text-gray-700 text-sm font-bold mb-0.5 "
            >
              Description (English):
            </label>
            <textarea
              rows={1}
              id="content_english"
              name="content_english"
              value={formData.content_english}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>
          <div>
            <label
              htmlFor="content_arabic"
              className="block text-gray-700 text-sm font-bold  mb-2"
            >
              Description (Arabic):
            </label>

            <textarea
              rows={1}
              id="content_arabic"
              name="content_arabic"
              value={formData.content_arabic}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>

          

          <div>
            <label
              htmlFor="content_turkish"
              className="block text-gray-700 text-sm font-bold  mb-0.5"
            >
              Description (Turkish):
            </label>
            <textarea
              rows={1}
              id="content_turkish"
              name="content_turkish"
              value={formData.content_turkish}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>
        </div>
        <div className="flex flex-col w-full">
        <div className="w-full"> 
            <label
              htmlFor="link"
              className="block text-gray-700 text-sm font-bold  mb-0.5"
            >
              Link :
            </label>
            <textarea
              rows={1}
              id="link"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className=" px-3 py-2 border w-full rounded-md focus:outline-none focus:border-blue-500"
              required
            ></textarea>
          </div>
        <button
          onClick={handleSubmit}
          className="bg-blue-500  text-white hover:bg-blue-600 duration-500 py-2 lg:w-2/12 rounded-sm "
        >
          Submit
        </button>
        </div>
      </div>
    </div>
  );
};

export default FormHome;
