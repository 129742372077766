import React, { useEffect, useState } from "react";
// import './App.css';
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";


import { deleteSliderProductAction, showProductSliderByProductIdAction } from "../../Action/ProductAction/ProductSliderAction";

const ProductContentByProductId = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const showProductSliderByProductId = useSelector(
    (state) => state.showProductSliderByProductId
  );
  const { loading, datas } = showProductSliderByProductId;

  useEffect(() => {
    dispatch(showProductSliderByProductIdAction(id));
    // console.log()
  }, []);

  const [idd,setId]=useState(null);
  const [isDeleteModalView,setIsDeleteModalView]=useState(false)
  const [isOpenModal,setIsOpenModal]=useState(false)

  
  const navigate = useNavigate();
  return (
    <>
      {datas == undefined || loading ? (
        ""
      ) : (
        <div className="container mx-auto mt-8">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className=" border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        English
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Turkesh
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Arabic
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {datas.map((product, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title.toString().slice(0,10)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title_tu.toString().slice(0,10)}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title_ar.toString().slice(0,10)}
                          </td>
                          <td>
                            <img src={product.image} className="h-20 bg-cover w-full" alt="" />
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                            <a
                              className="text-red-500 cursor-pointer hover:text-red-700"
                              // href="#"
                              onClick={() => {
                                setIsDeleteModalView(true)
                                setId(product.id)
                              }}
                            >
                              Delete
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-left whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("/product/contetnByProductId/edit/" + product.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>

                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

<>
      {isDeleteModalView && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white p-6">
                <div className="text-center">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Confirm Deletion</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Are you sure you want to delete this item?</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={()=>{
                    dispatch(deleteSliderProductAction(idd));
                    dispatch(showProductSliderByProductIdAction(id))
                    dispatch(showProductSliderByProductIdAction(id));
                    setIsDeleteModalView(false)
                  }}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={()=>setIsDeleteModalView(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    </>
  );
};

export default ProductContentByProductId;

