import { FETCH_USER_FAIL, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from "../ActionType/UserAuth/UserAuthType";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUSET,
  USER_LOGIN_SUCCESS,
} from "../ActionType/loginTypes";

export const loginUserReducer = (
  state = { loading: true, loggedIn: false, user: [], error:"" },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUSET:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,

        loggedIn: true,
        user: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,

        loggedIn: false,
        user: [],
        error: "Please Check your Validate",
      };
    default:
      return state;
  }
};



export const getUserAuthReducer = (
  state = { loading: true, user: [], error:"" },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        loading: false,

        // loggedIn: true,
        user: action.payload,
      };
    case FETCH_USER_FAIL:
      return {
        loading: false,

        loggedIn: false,
        user: [],
        error: "Please Check your Validate",
      };
    default:
      return state;
  }
};