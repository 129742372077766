import React from 'react'
import { IoMdLogOut } from 'react-icons/io'
import { MdPassword } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { logOut } from '../Action/login'
import { useNavigate } from 'react-router-dom'
const Home = () => {
  const dispatch=useDispatch();
  const handelLogOut=()=>{
    dispatch(logOut())
  }
  const navigate=useNavigate();
  return (
    <div className='px-10  flex mt-2 flex-wrap gap-4'>
        <div className='bg-white rounded-md shadow-md p-20' onClick={handelLogOut}>
          <p className='text-6xl text-red-500'> <IoMdLogOut  /></p>
          <p className='font-bold'>LogOut</p>
        </div>
        <div className='bg-white rounded-md shadow-md p-20' onClick={()=>{
          navigate("/password")
        }}>
          <p className='text-6xl text-blue-500'> <MdPassword /></p>
          <p className='text-center font-bold'>Password</p>
        </div>
    </div>
  )
}

export default Home