import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  deleteProductAction,
  getProductAction,
} from "../../Action/ProductAction/productAction";
// import { FaPlus, FaTrash } from "react-icons/fa6";
import { createSliderProductAction } from "../../Action/ProductAction/ProductSliderAction";

const Product = () => {
  const getProduct = useSelector((state) => state.getProduct);
  const { Products, loading, total } = getProduct;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(1000);
  useEffect(() => {
    dispatch(getProductAction(page));
  }, []);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imageSlide, setImageSlide] = useState();

  const handleSubmit = () => {
    dispatch(
      createSliderProductAction(
        id,
        formData.title_en,
        formData.title_ar,
        formData.title_tu,
        imageSlide,
        formData.content_english,
        formData.content_arabic,
        formData.content_turkish
      )
    );
    setIsOpenModal(false);
  };
  const [formData, setFormData] = useState({
    title_en: "",
    title_ar: "",
    title_tu: "",
    content_arabic: "",
    content_english: "",
    content_turkish: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
      // reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  return (
    <>
      {loading || Products == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap">
            <div>
              <button
                onClick={() => navigate("/bgProduct")}
                className="bg-indigo-500 rounded-md shadow-md duration-500 hover:bg-indigo-600 mt-2 p-2 text-white ml-3"
              >
                Add Background Image
              </button>
              <button
                onClick={() => navigate("/productForm")}
                className="bg-blue-500 rounded-md shadow-md duration-500 hover:bg-blue-600 mt-2 p-2 text-white ml-3"
              >
                Add New
              </button>
            </div>

            <div>
              <button
                onClick={() => navigate("/ShowDetailsImageProduct")}
                className="bg-red-500 rounded-md shadow-md duration-500 hover:bg-red-600 mt-2 p-2 text-white ml-3"
              >
                Show Details Image
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className=" border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        English
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Turkesh
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Arabic
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Content
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {Products.map((product, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title_tu}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {product.title_ar}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-left ">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("contetnByProductId/" + product.id);
                              }}
                            >
                              Content
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("edit/" + product.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(product.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>

                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-blue-500 text-4xl hover:text-blue-700"
                              href="#"
                              onClick={() => {
                                setIsOpenModal(true);
                                setId(product.id);
                              }}
                            >
                              +
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    

      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteProductAction(id));
                  dispatch(getProductAction(page));
                  dispatch(getProductAction(page));
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpenModal && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white lg:w-5/12 p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to Add Picture?
            </p>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <div>
                  <label
                    htmlFor="title"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Title English:
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title_en"
                    value={formData.title_en}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="title"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Title Arabic:
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title_ar"
                    value={formData.title_ar}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="title"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Title Turkish:
                  </label>
                  <input
                    type="text"
                    id="title"
                    name="title_tu"
                    value={formData.title_tu}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div>

                <div>
                  <label
                    htmlFor="content_english"
                    className="block text-gray-700 text-sm font-bold mb-0.5 "
                  >
                    Content (English):
                  </label>
                  <textarea
                    rows={1}
                    id="content_english"
                    name="content_english"
                    value={formData.content_english}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  ></textarea>
                </div>

                <div>
                  <label
                    htmlFor="content_arabic"
                    className="block text-gray-700 text-sm font-bold  mb-2"
                  >
                    Content (Arabic):
                  </label>

                  <textarea
                    rows={1}
                    id="content_arabic"
                    name="content_arabic"
                    value={formData.content_arabic}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  ></textarea>
                </div>


                <div>
                  <label
                    htmlFor="content_turkish"
                    className="block text-gray-700 text-sm font-bold  mb-0.5"
                  >
                    Content (Turkish):
                  </label>
                  <textarea
                    rows={1}
                    id="content_turkish"
                    name="content_turkish"
                    value={formData.content_turkish}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-2 mb-2 ">
              <input
                type="file"
                onChange={(e) => setImageSlide(e.target.files[0])}
                className="border-2 w-full rounded-md shadow-md p-2"
              />
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  // setPicture([])
                  setIsOpenModal(false);
                }}
                className="text-white px-2 rounded-md  bg-red-500 py-1 ml-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="text-white px-2 rounded-md  bg-teal-500 py-1 ml-2"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
