import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import { deleteGalleryAction, getGalleryAction } from "../../Action/GalleryAction/GalleryAction";
import { FaPlus, FaTrash } from "react-icons/fa6";
import {
  deleteCatalogeAction,
  getCatalogeAction,
} from "../../Action/CatalogeAction/CatalogeAction";
import { createPdfAction } from "../../Action/PdfAction/pdfAction";
import { createPictureAction } from "../../Action/PictureAction/PictureAction";
const Cataloge = () => {
  const getCataloge = useSelector((state) => state.getCataloge);
  const { cataloges, loading, total } = getCataloge;
  const [pdf, setPdf] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getCatalogeAction(page));
    // console.log(total)
  }, []);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [isPdfModal, setIsPdfModa] = useState(false);
  const [isPictureModal, setIsPictureModa] = useState(false);

  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const [picture, setPicture] = useState([]);
  const handelInputChange = (event, index) => {
    const newValue = [...picture];
    newValue[index] = event.target.files[0];
    // console.log(newValue)
    setPicture(newValue);
  };
  const validateImages = () => {
    // Filter out undefined or null elements
    const filteredPicture = picture.filter(item => item);
    // Check if any file input is empty
    return filteredPicture.every(item => item.length > 0);
  };
  const [errorPicture,setErrorPicture]=useState("")
  
  return (
    <>
      {loading || cataloges == undefined ? (
        ""
      ) : (
        <div className="flex flex-col">
          <div className="flex">
            <div className="flex flex-wrap">
            <button
                onClick={() => navigate("/bgCataloge")}
                className="bg-indigo-500 rounded-md shadow-md duration-500 hover:bg-indigo-600 mt-2 p-2 text-white ml-3"
              >
                Add Background Image
              </button>
              <button
                onClick={() => navigate("/catalogeForm")}
                className="bg-blue-500 rounded-md shadow-md duration-500 hover:bg-blue-600 mt-2 p-2 text-white ml-3"
              >
                Add New
              </button>
              <button
                onClick={() => navigate("/pdf")}
                className="bg-green-500 rounded-md shadow-md duration-500 hover:bg-green-600 mt-2 p-2 text-white ml-3"
              >
                Show Pdf
              </button>
              <button
                onClick={() => navigate("/picture")}
                className="bg-red-500 hidden rounded-md shadow-md duration-500 hover:bg-red-600 mt-2 p-2 text-white ml-3"
              >
                Show Picture
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className=" border rounded-lg">
                <table className="min-w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        English
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Turkesh
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Arabic
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        PDF 
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                       Show PDF  
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {cataloges.map((cataloge, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {cataloge.en_text}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {cataloge.tu_text}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {cataloge.ar_text}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("edit/" + cataloge.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="px-6 gap-2 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="flex gap-2   justify-center cursor-pointer hover:text-green-700"
                              // href="#"
                            >
                              <p
                                className="text-blue-400 w-full"
                                onClick={() => {
                                  setIsPdfModa(true);
                                  setId(cataloge.id);
                                }}
                              >
                                PDF
                              </p>
                              <div
                                className="text-indigo-500 hidden"
                                onClick={() => {
                                  setId(cataloge.id);
                                  setIsPictureModa(true);
                                }}
                              >
                                <p>Picture</p>
                              </div>
                            </a>
                          </td>

                          <td className="pr-16 gap-2 py-4  text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="flex gap-2   justify-center cursor-pointer hover:text-green-700"
                              // href="#"
                            >
                              <p
                                className="text-blue-400 w-full"
                                onClick={() => {
                                  setIsPdfModa(true);
                                  navigate("/showPdf/"+cataloge.id)
                                  // setId();
                                }}
                              >
                                PDF
                              </p>
                              <div
                                className="text-indigo-500 hidden"
                                onClick={() => {
                                  navigate("/showPicture/"+cataloge.id)
                                }}
                              >
                                <p>Picture</p>
                              </div>
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(cataloge.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}


      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete?
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteCatalogeAction(id));
                  dispatch(getCatalogeAction(page));
                  dispatch(getCatalogeAction(page));
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isPdfModal && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to Add PDF?
            </p>
            <div>
              <input
                onChange={(e) => {
                  setPdf(e.target.files[0]);
                }}
                type="file"
                accept=".pdf"
                className="input"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsPdfModa(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setIsPdfModa(false);
                  dispatch(createPdfAction(pdf, id));
                }}
                className="text-green-500 px-4 py-2 ml-2"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}

      {isPictureModal && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to Add Picture?
            </p>

            <div className="flex flex-col mt-2 mb-2 ">
              <div
                onClick={() => {
                  setPicture([...picture, ""]);
                }}
                className="w-full     text-xl   "
              >
                <p className="flex   text-blue-500 cursor-pointer  hover:text-blue-600  justify-end">
                  <FaPlus />
                </p>
              </div>
              {picture.map((item, index) => {
                return (
                  <div className="flex gap-3 items-center">
                  <input
                    onChange={(e) => {
                      handelInputChange(e, index);
                    }}
                    type="file"
                    accept=""
                    className="input border-2 mt-2"
                  />
                  <div
                  onClick={()=>{
                    const deleteValue=[...picture];
                    deleteValue.splice(index,1);
                    setPicture(deleteValue)
                  }}
                  className="bg-red-500 mt-1 rounded-md shadow-md px-2 py-2 text-white">
                  <FaTrash />
                  </div>
                  </div>
                );
              })}
            </div>
            <div className="text-red-500 text-lg">{errorPicture}</div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setPicture([])
                  setIsPictureModa(false);
                }}
                className="text-white px-2 rounded-md  bg-red-500 py-1 ml-2"
              >
                Cancel
              </button>
              {
                picture.length<=0?"":<button
                onClick={() => {
                  
                    setIsPictureModa(false);
                    dispatch(createPictureAction(picture, id));
                    setErrorPicture("")
                    setPicture([])
                }}
                className="text-white px-2 rounded-md  bg-green-500 py-1 ml-2"
              >
                Save
              </button>
              }
              
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cataloge;
