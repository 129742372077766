import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { deleteBackgroundContactAction, getBackgroundContactAction } from "../../Action/backgroundContactAction/BackgroundContactAction";
const Contact = () => {
  const getBackgrounContact = useSelector((state) => state.getBackgrounContact);
  const { bgContacts, loading } = getBackgrounContact;
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getBackgroundContactAction());
  }, []);
  const [isDeleteModalView, setIsDeleteModalView] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      {loading || bgContacts == undefined ? (
        "dfsfds"
      ) : (
        <div className="flex flex-col">
          <div className="flex">
            <div>
              {
                bgContacts.length>0?"":<button 
                onClick={()=>navigate("/ContactForm")} className="bg-blue-500 rounded-md shadow-md duration-500 hover:bg-blue-600 mt-2 p-2 text-white ml-3">
                  Add New
                </button>
              }
              
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="border w-full overflow-auto rounded-lg">
                <table className=" overflow-auto w-full divide-y bg-white divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                      >
                        Image
                      </th>
                      
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Edit
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {bgContacts.map((contact, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1} 
                          </td>
                          
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            <img src={contact.image} className="h-28 object-cover w-full" alt="" />
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-green-500 cursor-pointer hover:text-green-700"
                              // href="#"
                              onClick={() => {
                                navigate("edit/" + contact.id);
                              }}
                            >
                              Edit
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a
                              className="text-red-500 hover:text-red-700"
                              href="#"
                              onClick={() => {
                                setIsDeleteModalView(true);
                                setId(contact.id);
                              }}
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}


      {isDeleteModalView && (
        <div
          className={`fixed inset-0   bg-gray-500 bg-opacity-75 flex items-center justify-center`}
        >
          <div className="bg-white p-6 rounded-md shadow-md">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to delete? 
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsDeleteModalView(false);
                }}
                className="text-blue-500 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(deleteBackgroundContactAction(id));
                  dispatch(getBackgroundContactAction());
                  dispatch(getBackgroundContactAction());
                  setIsDeleteModalView(false);
                }}
                className="text-red-500 px-4 py-2 ml-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
