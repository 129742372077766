// Login.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Action/login";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const handleLogin = (event) => {
    event.preventDefault();

    dispatch(login(userName, password));
  };
  const loginUser = useSelector((state) => state.loginUser);
  const { loggedIn, loading,user } = loginUser;
  const navigate = useNavigate();
  useEffect(() => {
    console.log(loggedIn);
    if (loggedIn) {
      navigate("/");
      return;
    }
    
  }, [ loading]);
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full p-6 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-6">Login</h2>
        <form>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Username
            </label>
            <input
              onChange={(e) => setUserName(e.target.value)}
              type="text"
              id="username"
              name="username"
              className="border-2 border-gray-300 p-2 w-full rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
              className="border-2 border-gray-300 p-2 w-full rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <button
            onClick={handleLogin}
            type="submit"
            className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
